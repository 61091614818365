@import '../../assets/scss/abstracts.scss';

.map {
  position: relative;
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-direction: column;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  @include respond-to(sm) {
    flex-direction: row;
  }
  .title {
    position: absolute;
    z-index: 20;
    right: 15px;
    bottom: 15px;
    font-size: 4px;
  }
}
