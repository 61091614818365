@import "../../assets/scss/abstracts.scss";

.display-map {
    width: 100%;
    height: 100vh;
    position: relative;
    z-index: 1;
    display: none;
    @include respond-to(sm) {
      display: block;
    }
    .leaflet-container{
      width: 100%;
      height: 100%;
    }
  }