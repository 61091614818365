@import '../../assets/scss/abstracts.scss';

.modal-header{
  display:flex;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid $main-color;
  h1{
    flex-grow: 2;
    color: $main-color;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    span{
      flex-grow: 2;
      margin-left: 5px;
    }

  }
  button{
    background: none;
    border: none;
  }
}