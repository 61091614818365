@import "../../../assets/scss/abstracts.scss";

.item-list {
  text-align: left;
  button{
    &:hover{
      border: 1px solid #fff;
      background-color: #fff;
      color: #1c7ed6;
    }
  }
  .mantine-button-inner{
    justify-content: flex-start;
  }

}
