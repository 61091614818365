// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
$font-stack: "Open Sans", sans-serif;
$font-script: cursive;

/// Colors
$link-color: #337ab7;

$text-color: #333333;
$main-color: #1c7ed6
;
$main-color-alpha: rgb(109, 163, 199);

$alert: #ff5252;

$verylight-grey: #f3f3f3;
$semi-light-grey: #e1e1e1;
$light-grey: #999999;
$border: #d5d5d5;
$mid-grey: #3d454b;
$dark-grey: #282a2c;

$black: #000000;
$white: #ffffff;

//$assetImagePath : "~@/assets/images";

/// Container's maximum width
$max-width: 1140px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  "xs": 540px,
  "sm": 720px,
  "md": 960px,
  "lg": 1140px,
  "xl": 1320px,
) !default;
