@import "../../assets/scss/abstracts.scss";

.sidebar {
  height: 100vh;
  width: 100%;
  position: relative;
  z-index: 1;
  background: $main-color;
  padding-top: 50px;
  @include respond-to(sm) {
    flex-direction: row;
    width: 30%;
    padding-top: 0;
  }
}

.list-bookcase{
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  .search{
    padding: 20px;
  }
  ul{
    overflow: auto;
    list-style: none;
    padding: 20px 20px 0;
    margin: 0;
    li{
      padding: 0;
      margin: 5px;
    }
  }
}
