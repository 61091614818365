// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
