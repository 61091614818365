@import '../../assets/scss/abstracts.scss';

.title {
  overflow: hidden;
  padding-top: 2em;
  font-size: 7px;
  text-align: center;
  @include respond-to(sm) {
    font-size: 14px;
  }
  h1 {
    line-height: 1em;
    border: solid currentcolor;
    border-width: 0 0.45em 0.45em;
    position: relative;
    padding: 0 1.5em;
    strong {
      display: inline-block;
      line-height: 1em;
      position: relative;
      margin-top: 0;
      font-weight: 800;
      &::before {
        content: "";
        border: solid currentcolor;
        border-width: 0.45em 0 0;
        width: 1000%;
        position: absolute;
        right: 105%;
        top: 0;
      }
      &::after {
        content: "";
        border: solid currentcolor;
        border-width: 0.45em 0 0;
        width: 1000%;
        position: absolute;
        left: 105%;
        top: 0;
      }
      & > span {
        text-transform: uppercase;
        font-size: 3em;
      }
    }
    & > span {
      font-size: 1em;
      display: block;
      font-weight: 300;
      padding: 0.5em 0;
    }
  }
}