// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

html{
  box-sizing: border-box;
  *, *::before, *::after {
    box-sizing: inherit;
  }
}

html, body{
  min-height: 100%;
}

body{
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #228be6;
  background-image: linear-gradient(135deg, #228be6 0%, #15aabf 100%);
  color: $text-color;
  font-size: 16px;
  line-height: 1.4em;
}

a{
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline; 
  }
}

a[type=button],
button{
  cursor: pointer;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none; 
  }
}

img{
  max-width: 100%;
  height: auto;
}

.container{
  max-width: $max-width;
  margin: 0 auto;
  padding: 0 10px;
}

.onlyDesktop{
  display: block;
  @include respond-to(sm) {
    display: none;
  }
}

.onlyMobile{
  display: none;
  @include respond-to(sm) {
    display: block;
  }
}