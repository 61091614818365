@import "../../assets/scss/fonts.scss";
@import "../../assets/scss/abstracts.scss";

.home {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  color: white;
}
.home-container {
  width: 380px;
  @include respond-to(sm) {
    width: 600px;
  }
  max-width: 90%;
  height: auto;
  text-align: center;
}